var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"templateForm",staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-12 col-sm-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.title","rules":"required","name":"The title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"title","size":"large","filterable":"","clearable":"","placeholder":_vm.$store.getters['languageMixin/getStringFromLanguage']('title'),"error":failed ? errors[0]: null,"input-classes":'select-default',"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('title'),"list":_vm.builderData.titleList,"listItemLabel":'name',"listItemValue":'name'},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"fr.first_name","rules":"required","name":"The First Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('First Name'),"name":"first_name","fou":""},model:{value:(_vm.formData.first_name),callback:function ($$v) {_vm.$set(_vm.formData, "first_name", $$v)},expression:"formData.first_name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"fr.last_name","rules":"required","name":"The Last Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Last Name'),"name":"last_name","fou":""},model:{value:(_vm.formData.last_name),callback:function ($$v) {_vm.$set(_vm.formData, "last_name", $$v)},expression:"formData.last_name"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"fr.occupation","rules":"required","name":"The Occupation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"occupation","size":"large","filterable":"","clearable":"","placeholder":_vm.$store.getters['languageMixin/getStringFromLanguage']('Occupation'),"error":failed ? errors[0]: null,"input-classes":'select-default',"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Occupation'),"list":_vm.builderData.occupationList,"listItemLabel":'name',"listItemValue":'name'},model:{value:(_vm.formData.occupation),callback:function ($$v) {_vm.$set(_vm.formData, "occupation", $$v)},expression:"formData.occupation"}})]}}],null,true)})],1),(_vm.formData.occupation === 'Autre : préciser')?_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"fr.profession","rules":"","name":"The profession"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('profession'),"name":"profession","fou":""},model:{value:(_vm.formData.profession),callback:function ($$v) {_vm.$set(_vm.formData, "profession", $$v)},expression:"formData.profession"}})]}}],null,true)})],1):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"email","rules":"required","name":"The Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Email'),"name":"email","fou":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})]}}],null,true)})],1)]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Profile Image')))]),(false)?_c('el-tooltip',{attrs:{"placement":"right"}},[_c('div',{attrs:{"slot":"content"},slot:"content"}),_c('span',{staticStyle:{"margin":"0 5px"}},[_c('svg',{staticClass:"bi bi-question-circle",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"}}),_c('path',{attrs:{"d":"M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"}})])])]):_vm._e(),_c('prime-uploader',{attrs:{"preview-width":'200px',"preview-height":'200px',"elementNum":1,"maxFileSize":2000000,"isEditMode":_vm.editMode},model:{value:(_vm.formData.profile_image),callback:function ($$v) {_vm.$set(_vm.formData, "profile_image", $$v)},expression:"formData.profile_image"}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.address","rules":"required","name":"The Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Address'),"name":"address","fou":""},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"fr.po_box","rules":"","name":"The po_box"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('PO Box'),"name":"po_box","fou":""},model:{value:(_vm.formData.po_box),callback:function ($$v) {_vm.$set(_vm.formData, "po_box", $$v)},expression:"formData.po_box"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"fr.city","rules":"required","name":"The City"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('City'),"name":"city","fou":""},model:{value:(_vm.formData.city),callback:function ($$v) {_vm.$set(_vm.formData, "city", $$v)},expression:"formData.city"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.country","rules":"required","name":"The Country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Country'),"name":"country","fou":""},model:{value:(_vm.formData.country),callback:function ($$v) {_vm.$set(_vm.formData, "country", $$v)},expression:"formData.country"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"fr.phone","rules":"required","name":"The Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Phone'),"name":"phone","fou":""},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"fr.other_phone","rules":"","name":"The Other Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Other Phone'),"name":"other_phone","fou":""},model:{value:(_vm.formData.other_phone),callback:function ($$v) {_vm.$set(_vm.formData, "other_phone", $$v)},expression:"formData.other_phone"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.type_id","rules":"required","name":"The Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"type_id","size":"large","filterable":"","clearable":"","placeholder":_vm.$store.getters['languageMixin/getStringFromLanguage']('Type'),"error":failed ? errors[0]: null,"input-classes":'select-default',"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Type'),"list":_vm.builderData.typeList,"listItemLabel":'name',"listItemValue":'id'},model:{value:(_vm.formData.type_id),callback:function ($$v) {_vm.$set(_vm.formData, "type_id", $$v)},expression:"formData.type_id"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"fr.facturation_pro_customer_id","rules":"","name":"The Facturation Pro - Customer ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Facturation Pro - Customer ID'),"name":"facturation_pro_customer_id","fou":""},model:{value:(_vm.formData.facturation_pro_customer_id),callback:function ($$v) {_vm.$set(_vm.formData, "facturation_pro_customer_id", $$v)},expression:"formData.facturation_pro_customer_id"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"fr.newsletter_subscription","rules":"required","name":"The newsletter_subscription"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"newsletter_subscription","size":"large","filterable":"","clearable":"","placeholder":_vm.$store.getters['languageMixin/getStringFromLanguage']('mailChimp subscription to the ifcia newsletter'),"error":failed ? errors[0]: null,"input-classes":'select-default',"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('MailChimp Subscription to the IFCIA Newsletter'),"list":_vm.builderData.choiceList,"listItemLabel":'label',"listItemValue":'value'},model:{value:(_vm.formData.newsletter_subscription),callback:function ($$v) {_vm.$set(_vm.formData, "newsletter_subscription", $$v)},expression:"formData.newsletter_subscription"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"fr.ancien","rules":"required","name":"The ancien"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"ancien","size":"large","filterable":"","clearable":"","placeholder":_vm.$store.getters['languageMixin/getStringFromLanguage']('ancien'),"error":failed ? errors[0]: null,"input-classes":'select-default',"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('ancien'),"list":_vm.builderData.choiceList,"listItemLabel":'label',"listItemValue":'value'},model:{value:(_vm.formData.ancien),callback:function ($$v) {_vm.$set(_vm.formData, "ancien", $$v)},expression:"formData.ancien"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"fr.number_chairs_in_office","rules":"","name":"The Number of chairs in the office"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Number of chairs in the office'),"name":"number_chairs_in_office","fou":""},model:{value:(_vm.formData.number_chairs_in_office),callback:function ($$v) {_vm.$set(_vm.formData, "number_chairs_in_office", $$v)},expression:"formData.number_chairs_in_office"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.notes","rules":"","name":"The Notes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text_area","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Notes'),"name":"notes","fou":""},model:{value:(_vm.formData.notes),callback:function ($$v) {_vm.$set(_vm.formData, "notes", $$v)},expression:"formData.notes"}})]}}],null,true)})],1)]),_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Is Active')))]),_vm._v(" "),_c('l-switch',{model:{value:(_vm.formData.is_active),callback:function ($$v) {_vm.$set(_vm.formData, "is_active", $$v)},expression:"formData.is_active"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1)]),_c('div',{staticClass:"card-footer text-right"},[_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Submit'))+" ")]),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/members/list')}}},[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Cancel'))+" ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }