<template>

  <div class="row" ref="itemView">

    <div class="col-12">

      <card>
        <div slot="header">
          <div class="header-content">
            <div class="form-title">
              <h4 class="card-title">
                {{ formTitle }}
              </h4>
            </div>
            <div class="header-actions">
              <router-link   v-if="$store.getters['auth/checkAccess']('members/get')"
                             v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Go back')"
                             class="btn-warning btn-simple btn-link"
                             :to="'/members'">
                <span class="btn-label px-2">
                    <i class="fas fa-arrow-left" style="font-size: 30px"></i>
                  </span>
              </router-link>
            </div>
          </div>
        </div>

        <div id="contactus-message" class="card-body">
          <div class="container">
            <div class="row contact-info">
              <div class="col-12 col-md-12 col-lg-12">
               <div class="member-box">
                 <span class="title-label">{{ $store.getters['languageMixin/getStringFromLanguage']('Personal Information') }}</span>
                 <div class="row">
                   <div class="col-md-9">
                     <div class="row">
                       <div class="col-md-6 typo-line">
                         <p>
                           <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Full Name') }}</span>
                           <span class="category-value" v-html="getValueOrDash(itemDetails, 'full_name_cms')"></span>
                         </p>
                       </div>
                     </div>
                     <div class="row">
                       <div class="typo-line">
                         <p>
                           <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Email') }}</span>
                           <span class="category-value"> {{ getValueOrDash(itemDetails, 'email') }}</span>
                         </p>
                       </div>
                     </div>
                     <div class="row">
                       <div class="col-md-6 typo-line">
                         <p>
                           <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Phone') }}</span>
                           <span class="category-value"> {{ getValueOrDash(itemDetails, 'phone') }}</span>
                         </p>
                       </div>
                       <div class="col-md-6 typo-line">
                         <p>
                           <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Other Phone') }}</span>
                           <span class="category-value"> {{ getValueOrDash(itemDetails, 'other_phone') }}</span>
                         </p>
                       </div>
                     </div>
                     <div class="row">
                       <div class="typo-line">
                         <p>
                           <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Occupation') }}</span>
                           <span class="category-value"> {{ getValueOrDash(itemDetails, 'occupation') }}</span>
                         </p>
                       </div>
                     </div>
                     <div class="row">
                       <div class="typo-line">
                         <p>
                           <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Birth Date') }}</span>
                           <span class="category-value"> {{ getValueOrDash(itemDetails, 'birth_date') }}</span>
                         </p>
                       </div>
                     </div>
                   </div>
                   <div class="col-md-3">
                     <img class="member-profile-image" :src="showImage(getValueOrDash(itemDetails, 'profile_image'))" alt="Profile Image"/>
                   </div>
                 </div>
               </div>
                <div class="member-box">
                  <span class="title-label">{{ $store.getters['languageMixin/getStringFromLanguage']('Address Information') }}</span>
                  <div class="row">
                    <div class="col-md-6 typo-line">
                      <p>
                        <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Address') }}</span>
                        <span class="category-value"> {{ getValueOrDash(itemDetails, 'address') }}</span>
                      </p>
                    </div>
                    <div class="col-md-6 typo-line">
                      <p>
                        <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Po Box') }}</span>
                        <span class="category-value"> {{ getValueOrDash(itemDetails, 'po_box') }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 typo-line">
                      <p>
                        <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('City') }}</span>
                        <span class="category-value"> {{ getValueOrDash(itemDetails, 'city') }}</span>
                      </p>
                    </div>
                    <div class="col-md-6 typo-line">
                      <p>
                        <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Country') }}</span>
                        <span class="category-value"> {{ getValueOrDash(itemDetails, 'country') }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="member-box">
                  <span class="title-label">{{ $store.getters['languageMixin/getStringFromLanguage']('Other Information') }}</span>
                  <div class="row">
                    <div class="typo-line">
                      <p>
                        <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Member Type') }}</span>
                        <span class="category-value"> {{ getValueOrDash(itemDetails, 'type').name }}</span>
                        <span  v-if="$store.getters['auth/checkAccess']('members/change-type')">
                      <ValidationProvider
                        vid="type_id"
                        rules=""
                        :name="$store.getters['languageMixin/getStringFromLanguage']('Change Member Type')"
                        v-slot="{ passed, failed,errors }">
                        <fg-select
                          name="type_id"
                          size="large"
                          filterable
                          clearable
                          :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Change Member Type')"
                          :error="failed ? errors[0]: null"
                          :input-classes="'select-default'"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Change Member Type')"
                          :list="memberTypeList"
                          :listItemLabel="'name'"
                          :listItemValue="'id'"
                          v-model="formData.type_id">
                        </fg-select>
                      </ValidationProvider>
                      <l-button @click="changeMemberType"
                                nativeType="submit"
                                type="info" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Submit') }}
                      </l-button>
                    </span>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="typo-line">
                      <p v-if="itemDetails">
                        <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Social Networks') }}</span>
                        <span class="category-value" v-for="social in itemDetails.social_networks">{{ social.name ? social.name:'' + ' : ' + social.value?social.value:'' }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="typo-line">
                      <p>
                        <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Created At') }}</span>
                        <span style="display:flex;justify-content: start;flex-direction: column;" class="category-value" v-html="getValueOrDash(itemDetails, 'creation_dates')"></span>
                      </p>
                    </div>
                  </div>
                  <div class="row" v-if="itemDetails && itemDetails.type.ident === 'student'">
                    <div class="typo-line">
                      <p>
                        <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Is student card checked ?') }}</span>
                        <span style="margin-left: 100px" :class="itemDetails.student_card_checked ? 'student-checked' : 'student-unchecked'" class="category-value"> {{ itemDetails.student_card_checked ? 'YES' : 'NO' }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="row" v-if="itemDetails && itemDetails.type.ident === 'student'">
                    <div class="typo-line">
                        <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Student card') }}</span>
                      <div v-if="itemDetails" class="col-md-12">
                        <img v-if="itemDetails.student_card" class="member-profile-image" :src="imagePath + getValueOrDash(itemDetails, 'student_card')" alt="Student Card"/>
                        <span class="category-value" v-else> {{ $store.getters['languageMixin/getStringFromLanguage']('Not uploaded') }}</span>
                      </div>
                    </div>
                    <div class="typo-line">
                      <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Student Number') }}</span>
                      <div v-if="itemDetails" class="col-md-12" style="margin-left:35px">
                        <span v-if="itemDetails.student_number">{{ itemDetails.student_number}}</span>
                      </div>
                    </div>
                    <div class="col-12" v-if="(itemDetails.student_card || itemDetails.student_number) && $store.getters['auth/checkAccess']('members/check-as-student')">
                      <button style="width: 130px" class="alert alert-success" @click="checkAsStudent">Is student ?</button>
                      <button style="width: 130px;margin-left: 15px" class="alert alert-warning" @click="uncheckAsStudent">Is not student ?</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="member-activity">
              <div class="category category-2" style="color: rgb(236, 100, 89);">{{ $store.getters['languageMixin/getStringFromLanguage']('Next Trainings') }} :</div>
              <div v-if="itemDetails">
                <div v-for="session in itemDetails.next_trainings">
                  <router-link
                               class="btn-success btn-simple btn-link"
                               :to="{ name: 'Show Registration Info', params: { type: 'session', id: session.id } }">
                        <span class="register_status badge" :style="{backgroundColor: session.status_cms.color, marginTop: '24px'} ">{{ session.status_cms.fr }}</span><span v-html="session.session.session_name_cms"></span>
                  </router-link>
                </div>
              </div>
            </div>
            <hr>
            <div class="member-activity">
              <div class="category category-2" style="color:  rgb(236, 100, 89)">{{ $store.getters['languageMixin/getStringFromLanguage']('Previous Trainings') }} :</div>
              <div v-if="itemDetails">
                <div v-for="session in itemDetails.previous_trainings">
                  <router-link
                               class="btn-success btn-simple btn-link"
                               :to="{ name: 'Show Registration Info', params: { type: 'session', id: session.id } }">
                               <span class="register_status badge" :style="{backgroundColor: session.status_cms.color, marginTop: '24px'} ">{{ session.status_cms.fr }}</span><span v-html="session.session.session_name_cms"></span>
                  </router-link>
                </div>
              </div>
            </div>
            <hr>
            <div class="member-activity">
              <div class="category category-2" style="color:  rgb(236, 100, 89)">{{ $store.getters['languageMixin/getStringFromLanguage']('Cycles') }} :</div>
              <div v-if="itemDetails">
                <div v-for="cycle in itemDetails.cycles">
                  <router-link
                    class="btn-success btn-simple btn-link"
                    :to="{ name: 'Show Registration Info', params: { type: 'cycle', id: cycle.id } }">
                    <span class="register_status badge" :style="{backgroundColor: cycle.status_cms.color, marginTop: '24px'} ">{{ cycle.status_cms.fr }}</span>{{ cycle.cycle.name }}
                  </router-link>
                </div>
              </div>
            </div>
            <hr>
            <div class="member-activity">
              <div class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('All Member Shared Files') }} :</div>
              <div v-if="itemDetails">
                <a target="_blank"
                   style="display: block"
                   v-for="file in itemDetails.files" :href="imagePath + file.file">
                  {{ $store.getters['languageMixin/getStringFromLanguage']('Click to download') }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer text-right">

        </div>
      </card>


    </div>

  </div>
</template>
<script>
// import { jsPDF } from "jspdf";

import FgSelect from "../../components/Inputs/formGroupSelect";
export default {

  components: {FgSelect},

  data() {
    return {
      id: undefined,
      formTitle: "",
      submitting: false,
      itemDetails: null,
      imagePath: process.env.VUE_APP_SERVER_IMAGE_URL,
      formData: {
        type_id: '',
      },
      memberTypeList: []
    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.itemView
    });

    this.id = this.$route.params['id'];
    this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Member Details");
    if (this.id !== undefined) {
      this.getBuilderData();
      this.getItem();
    } else {
      this.$notify({
        message: this.$store.getters['languageMixin/getStringFromLanguage']("Member Not Found"),
        timeout: 2000,
        type: 'danger'
      });
      this.loader.hide();
    }
  },


  methods: {
    showImage(image){
      if (image.startsWith('https://medias.ifcia-antoun.com')){
        return image;
      }
      return  process.env.VUE_APP_SERVER_IMAGE_URL + image;
    },
    getBuilderData() {
      this.axios.get("members/builder").then((response) => {
        this.memberTypeList = response.data.memberTypes;
      }).catch((error) => {
        console.error(error);
      })
    },
    getItem() {
      this.axios.post("members/get", {id: +this.id}).then((response) => {
        this.itemDetails = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.$notify({
            message: this.$store.getters['languageMixin/getStringFromLanguage']("Member Not Found"),
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    checkAsStudent() {
      this.axios.post("members/check-as-student", {id: this.id}).then((response) => {
        this.loader.hide();
        this.getItem();
        this.$notify({
          message: "This Member is confirmed as student",
          timeout: 2000,
          type: 'success'
        });
      }).catch((error) => {
        if (error.response.status === 404) {
          this.$notify({
            message: "Member Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    uncheckAsStudent() {
      this.axios.post("members/uncheck-as-student", {id: this.id}).then((response) => {
        this.loader.hide();
        this.getItem();
        this.$notify({
          message: "This Member is confirmed as not a student",
          timeout: 2000,
          type: 'warning'
        });
      }).catch((error) => {
        if (error.response.status === 404) {
          this.$notify({
            message: "Member Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    getValueOrDash(obj, key) {
      if (obj && obj[key]) {
        return obj[key];
      }
      return "-";
    },
    changeMemberType() {
      let request;
      let successMessage;
      this.submitting = true;

      request = this.axios.post("members/change-type/" + this.id, this.formData);
      successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Member Type Changed Successfully");
      this.getItem();

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        // this.$router.push("/newsletter-templates/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          this.$notify({
            message: error.response.data.message,
            timeout: 3000,
            type: 'danger'
          })
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
  }
}
</script>

<style>
.header-content{
  display: flex;
  justify-content: space-between;
}
.typo-line{
  padding-left: 65px;
  margin-bottom: 20px;
}
.contact-info{
  margin-bottom: 20px;
}
.category{
  color: #888888;
}
.category-2{
  top: 11px !important;
}
.category-value{
  margin-left: 70px;
}
/*.message-content{*/
/*  font-size: 16px !important*/
/*}*/
.member-box {
  position: relative;
  border: 1px solid black;
  padding: 33px;
  padding-left: 30px;
  margin-bottom: 30px;
}
.title-label {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: white;
  padding: 0 5px;
}
.member-profile-image{
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.student-checked{
  padding: 10px;
  background-color: #a1e82c;
  color: #ffffff;
}
.student-unchecked{
  padding: 10px;
  background-color: #ffbc67;
  color: #ffffff;
}
.member-activity{
  margin-top: 15px;
  /*border-bottom: 1px solid;*/
}
.register_status{
  color: #ffff;
}
</style>
