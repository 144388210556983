<template>

  <div class="row" ref="templateForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.title"
                rules="required"
                name="The title"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="title"
                  size="large"
                  filterable
                  clearable
                  :placeholder="$store.getters['languageMixin/getStringFromLanguage']('title')"
                  :error="failed ? errors[0]: null"
                  :input-classes="'select-default'"
                  :label="$store.getters['languageMixin/getStringFromLanguage']('title')"
                  :list="builderData.titleList"
                  :listItemLabel="'name'"
                  :listItemValue="'name'"
                  v-model="formData.title">
                </fg-select>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.first_name"
                rules="required"
                name="The First Name"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('First Name')"
                          name="first_name"
                          fou
                          v-model="formData.first_name">
                </fg-input>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider
                vid="fr.last_name"
                rules="required"
                name="The Last Name"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Last Name')"
                          name="last_name"
                          fou
                          v-model="formData.last_name">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.occupation"
                rules="required"
                name="The Occupation"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="occupation"
                  size="large"
                  filterable
                  clearable
                  :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Occupation')"
                  :error="failed ? errors[0]: null"
                  :input-classes="'select-default'"
                  :label="$store.getters['languageMixin/getStringFromLanguage']('Occupation')"
                  :list="builderData.occupationList"
                  :listItemLabel="'name'"
                  :listItemValue="'name'"
                  v-model="formData.occupation">
                </fg-select>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-6" v-if="formData.occupation === 'Autre : préciser'">
              <ValidationProvider
                vid="fr.profession"
                rules=""
                name="The profession"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('profession')"
                          name="profession"
                          fou
                          v-model="formData.profession">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="email"
                rules="required"
                name="The Email"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Email')"
                          name="email"
                          fou
                          v-model="formData.email">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group">
            <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Profile Image') }}</label>
            <el-tooltip placement="right" v-if="false">
              <div slot="content"></div>
              <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
            </el-tooltip>
            <prime-uploader
              :preview-width="'200px'"
              :preview-height="'200px'"
              :elementNum="1"
              :maxFileSize="2000000"
              :isEditMode="editMode"
              v-model="formData.profile_image"
            >
            </prime-uploader>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.address"
                rules="required"
                name="The Address"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Address')"
                          name="address"
                          fou
                          v-model="formData.address">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.po_box"
                rules=""
                name="The po_box"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('PO Box')"
                          name="po_box"
                          fou
                          v-model="formData.po_box">
                </fg-input>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider
                vid="fr.city"
                rules="required"
                name="The City"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('City')"
                          name="city"
                          fou
                          v-model="formData.city">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.country"
                rules="required"
                name="The Country"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Country')"
                          name="country"
                          fou
                          v-model="formData.country">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.phone"
                rules="required"
                name="The Phone"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Phone')"
                          name="phone"
                          fou
                          v-model="formData.phone">
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.other_phone"
                rules=""
                name="The Other Phone"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Other Phone')"
                          name="other_phone"
                          fou
                          v-model="formData.other_phone">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.type_id"
                rules="required"
                name="The Type"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="type_id"
                  size="large"
                  filterable
                  clearable
                  :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Type')"
                  :error="failed ? errors[0]: null"
                  :input-classes="'select-default'"
                  :label="$store.getters['languageMixin/getStringFromLanguage']('Type')"
                  :list="builderData.typeList"
                  :listItemLabel="'name'"
                  :listItemValue="'id'"
                  v-model="formData.type_id">
                </fg-select>
              </ValidationProvider>
            </div>
          </div>


          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.facturation_pro_customer_id"
                rules=""
                name="The Facturation Pro - Customer ID"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Facturation Pro - Customer ID')"
                          name="facturation_pro_customer_id"
                          fou
                          v-model="formData.facturation_pro_customer_id">
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.newsletter_subscription"
                rules="required"
                name="The newsletter_subscription"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="newsletter_subscription"
                  size="large"
                  filterable
                  clearable
                  :placeholder="$store.getters['languageMixin/getStringFromLanguage']('mailChimp subscription to the ifcia newsletter')"
                  :error="failed ? errors[0]: null"
                  :input-classes="'select-default'"
                  :label="$store.getters['languageMixin/getStringFromLanguage']('MailChimp Subscription to the IFCIA Newsletter')"
                  :list="builderData.choiceList"
                  :listItemLabel="'label'"
                  :listItemValue="'value'"
                  v-model="formData.newsletter_subscription">
                </fg-select>
              </ValidationProvider>
            </div>
            </div>


          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.ancien"
                rules="required"
                name="The ancien"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="ancien"
                  size="large"
                  filterable
                  clearable
                  :placeholder="$store.getters['languageMixin/getStringFromLanguage']('ancien')"
                  :error="failed ? errors[0]: null"
                  :input-classes="'select-default'"
                  :label="$store.getters['languageMixin/getStringFromLanguage']('ancien')"
                  :list="builderData.choiceList"
                  :listItemLabel="'label'"
                  :listItemValue="'value'"
                  v-model="formData.ancien">
                </fg-select>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.number_chairs_in_office"
                rules=""
                name="The Number of chairs in the office"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Number of chairs in the office')"
                          name="number_chairs_in_office"
                          fou
                          v-model="formData.number_chairs_in_office">
                </fg-input>
              </ValidationProvider>
            </div>
            </div>

          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.notes"
                rules=""
                name="The Notes"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text_area"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Notes')"
                          name="notes"
                          fou
                          v-model="formData.notes">
                </fg-input>
              </ValidationProvider>
            </div>
            </div>


            <div class="form-group pt-4">
              <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Is Active') }}</label>&nbsp;
              <l-switch v-model="formData.is_active">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
              </l-switch>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Submit') }}
            </l-button>
            <l-button @click="$router.push('/members/list')" type="danger" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Cancel') }}
            </l-button>
          </div>

          </card>
      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {mapGetters} from "vuex";
import { required, email } from "vee-validate/dist/rules";
import {Option, Select, Tooltip} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader,  PrimeUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    ImageUploader,
    PrimeUploader,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {},
      editorConfig: this.getEditorConfig(),
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      imagesExtensions: this.getImagesExtensions(),
      videoExtensions: this.getVideoExtensions(),
      defaultFormData: {
        first_name: "",
        last_name: "",
        title: "",
        gender: "",
        occupation: "",
        phone: "",
        other_phone: "",
        profile_image: "",
        birth_date: "",
        social_networks: [],
        type_id: "",
        address: "",
        po_box: "",
        city: "",
        country: "",
        is_active: true,
        email: "",
        old_id: "",
      },

      builderData: {
        typeList: [],
        titleList: [],
        occupationList: [],
        choiceList: []
      },
      wizardModel: {}

    };
  },

  computed: {
    ...mapGetters({
      languageList: "global/languageList"
    })
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.templateForm
    });
    this.setNullFormData();

    let data = {}
    this.axios.get("members/builder", data).then((response) => {
      this.builderData.typeList = response.data.memberTypes;
      this.builderData.titleList = [ { 'name': "Mme"}, {'name': "Mr"} ]
      this.builderData.occupationList = [  {'name' : 'Docteur'} , {'name' : 'Étudiant·e'},
      {'name' : 'Prothésiste'}, {'name' : 'Assistant·e'}, {'name' : 'Orthodontiste'},
      {'name' : 'Communicant·e'}, {'name' : 'Autre : préciser'}  ]
      this.builderData.choiceList = [ {'label': 'Oui', 'value': true}, {'label': 'Non', 'value': false}]

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Edit Member");
        this.getItem();
      } else {
        this.editMode = false;
        this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Add Member");
        this.formData = {type_id:response.data.guestType};
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getItem() {
      let data = {
        'id': this.id,
      };
      this.axios.post("members/get", data).then((response) => {
        this.formData = response.data;
        // this.vueDate();
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: this.$store.getters['languageMixin/getStringFromLanguage']("Members Not Found"),
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },

    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      // this.laravelDate();
      if (this.editMode === true) {
        request = this.axios.put("members/update/" + this.id, this.formData);
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Members Updated Successfully");
      } else {
        request = this.axios.post("members/create", this.formData);
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Members Added Successfully");
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 6000,
          type: 'success'
        })
        this.$router.push("/members/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          console.log(error.response.data.errors)
          this.$refs.formValidator.setErrors(error.response.data.errors);
          // this.$notify({
          //   message: this.$store.getters['languageMixin/getStringFromLanguage']("Please check the missing fields"),
          //   timeout: 2000,
          //   type: 'danger'
          // });
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    validateStep(ref) {
      if (Array.isArray(this.$refs[ref])){
        return this.$refs[ref][0].validate();
      }else{
        return this.$refs[ref].validate();
      }
    },
    onStepValidated(validated, model) {
      this.wizardModel = {...this.wizardModel, ...model}
    },
    submitStep(data, lang, isSubmit){
      this.formData[lang] = data;
      if (isSubmit){
        this.submit();
      }
    },
    setNullFormData(){
      this.defaultFormData['fr'] = {
        name: '',
        ident: '',
      };
      this.languageList.forEach(lang => {
        this.defaultFormData[lang] = {
          name: '',
          ident: '',
        }
      })
    },
    vueDate() {
      this.formData['fr'].date = (this.formData['fr'].date) ? this.formData['fr'].date.replace(' ', 'T') : this.formData['fr'].date;
    },
    laravelDate() {
      this.formData['fr'].date = (this.formData['fr'].date) ? this.formData['fr'].date.replace('T', ' ') : this.formData['fr'].date;
    },
  }
}
</script>
